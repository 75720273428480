import React, { useEffect, useRef } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { NO_TEXT } from "../constants"
import { splitOnNewline } from "../utils"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

function ExpandedPerson({ setExpandedPerson, frontmatter, styles }) {
  const wrapperRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setExpandedPerson(NO_TEXT)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [setExpandedPerson])

  if (!frontmatter || frontmatter === NO_TEXT) {
    return <></>
  } else {
    const { name, bio, role, longRole, regImage, regImageAlt } = frontmatter

    const getBioText = () => {
      if (bio) {
        return splitOnNewline(bio)
      }
    }

    const closeWindow = () => {
      setExpandedPerson(NO_TEXT)
    }

    const whichRole = longRole === "" ? role : longRole
    return (
      <nav
        ref={wrapperRef}
        className={styles.expanded_person}
        onKeyDown={(key) => {
          if (key === 13) {
            closeWindow()
          }
        }}
      >
        <div className={styles.expanded_person_inner}>
          <div className={styles.circle}>
            <GatsbyImage alt={regImageAlt} image={regImage} />
          </div>
          <h2>{name}</h2>
          <hr />
          <p>{whichRole}</p>
          {getBioText().map((text, index) => {
            return (
              <p className={styles.blurb_text} key={index}>
                {text}
              </p>
            )
          })}
        </div>
        <button className={styles.clickable_area} onClick={() => closeWindow()}>
          <FontAwesomeIcon
            title="Close expanded team member"
            tabIndex={0}
            icon={faTimes}
            className={styles.close_expanded_person}
          />
        </button>
      </nav>
    )
  }
}

export default ExpandedPerson
