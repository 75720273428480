import React, { useState } from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Team from "../components/team"
import { headerNames } from "../components/navigation/header"
import { navItems } from "../components/team/team-nav-bar"

function IndexPage() {
  const [currentSwapperItem, setCurrentSwapperItem] = useState(
    navItems.leadership.name
  )
  const [swapperVisible, setSwapperVisible] = useState(true)

  return (
    <Layout
      activeHeader={headerNames.team}
      setCurrentSwapperItem={setCurrentSwapperItem}
      setSwapperVisible={setSwapperVisible}
    >
      <SEO title="Team" />
      <Team
        currentSwapperItem={currentSwapperItem}
        swapperVisible={swapperVisible}
      />
    </Layout>
  )
}

export default IndexPage
