import React from "react"
import { getGridCssCenteringClass } from "../utils"
import Person from "./person"

function PersonList(props) {
  const { data, pageStyle, numCols, setExpandedPerson } = props
  return (
    <div className={pageStyle}>
      {data.map((nodeObj, index) => {
        const specialGridCss = getGridCssCenteringClass(
          index,
          data.length,
          numCols
        )

        return (
          <Person
            key={index}
            specialGridCss={specialGridCss}
            name={nodeObj.name.text}
            bio={nodeObj.bio.text}
            role={nodeObj.role.text}
            longRole={nodeObj.long_role.text}
            regImage={nodeObj.regular_image.gatsbyImageData}
            regImageAlt={nodeObj.regular_image.alt}
            funImage={nodeObj.fun_image.gatsbyImageData}
            funImageAlt={nodeObj.fun_image.alt}
            setExpandedPerson={setExpandedPerson}
          />
        )
      })}
    </div>
  )
}

export default PersonList
