// extracted by mini-css-extract-plugin
export var blurb_text = "team-module--blurb_text--sQJ21";
export var board_members = "team-module--board_members--uT3nm";
export var circle = "team-module--circle--sKXUb";
export var clickable_area = "team-module--clickable_area--9R4Mf";
export var close_expanded_person = "team-module--close_expanded_person--zXQhD";
export var community_teachers = "team-module--community_teachers--oB2VK";
export var container = "team-module--container--74uk-";
export var container_animation = "team-module--container_animation--dmkzE";
export var container_animation_hide = "team-module--container_animation_hide--YjO76";
export var container_animation_show = "team-module--container_animation_show--dC8Jy";
export var content_swapper_container = "team-module--content_swapper_container--dgILx";
export var content_swapper_container_show = "team-module--content_swapper_container_show--djDuc";
export var drop_down_hide = "team-module--drop_down_hide--TP+Fq";
export var drop_down_show = "team-module--drop_down_show--RT27z";
export var expanded_person = "team-module--expanded_person--Hh2nA";
export var expanded_person_inner = "team-module--expanded_person_inner--bs4ol";
export var hide = "team-module--hide--GMalS";
export var join_us_edge_case = "team-module--join_us_edge_case--qzxQT";
export var join_us_image = "team-module--join_us_image--HnWXL";
export var join_us_image_container = "team-module--join_us_image_container--pv5Er";
export var join_us_team_container = "team-module--join_us_team_container--ddIPI";
export var join_us_team_sectional = "team-module--join_us_team_sectional--Nnw0J";
export var leadership = "team-module--leadership--bkgXd";
export var non_profit_board_members = "team-module--non_profit_board_members--f707E";
export var people_page_container = "team-module--people_page_container--5KDzf";
export var person = "team-module--person--JElwy";
export var reverse_container_padding = "team-module--reverse_container_padding--4mWU-";
export var section_bottom_triangle = "team-module--section_bottom_triangle--0Nard";
export var section_boxes = "team-module--section_boxes--3xFu9";
export var section_half_circle = "team-module--section_half_circle--iTSg8";
export var show = "team-module--show--fNFM7";
export var social_work_interns = "team-module--social_work_interns--26g5m";
export var summer_intern_container = "team-module--summer_intern_container--8N3rA";
export var summer_intern_image = "team-module--summer_intern_image--+7XmU";
export var summer_interns_grid = "team-module--summer_interns_grid--TVSj4";
export var summer_interns_image_container = "team-module--summer_interns_image_container--ixvpZ";
export var support_staff = "team-module--support_staff--b-Yg7";
export var teacher_padding = "team-module--teacher_padding--mmEyN";
export var teachers = "team-module--teachers--Aca7Y";