import React from "react"
import * as styles from "../team.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"

function SummerInterns({year, image, blurb}) {

  return (
    <div className={styles.summer_intern_container}>
      <h2>{year} Summer Interns</h2>
      <div>
        <div className={styles.summer_interns_image_container}>
          <GatsbyImage
            className={styles.summer_intern_image}
            alt={image.alt}
            image={image.gatsbyImageData}
          />
        </div>

        <ReactMarkdown source={blurb} />
      </div>
    </div>
  )
}

export default SummerInterns
