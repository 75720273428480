import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import * as styles from "./team.module.scss"
import { getContentSwapAnimation } from "../content-swapper-utils"
import { NO_TEXT, DESKTOP_EX } from "../constants"
import ExpandedPerson from "./expanded-person"
import PropTypes from "prop-types"
import PersonList from "./person-list"

function ReusableTeamPage(props) {
  let containerAnimation = getContentSwapAnimation(props.visible)

  //TODO: pageStyle should come from numCols, it can be calced and doesn't do anything else of value
  const { data, pageStyle } = props

  const setExpandedPerson = (frontmatter) => {
    if(frontmatter === NO_TEXT) {
      ReactDOM.unmountComponentAtNode(document.getElementById("boggs-school-modal"))
    } else {
      const modal = React.createElement(ExpandedPerson, {
        setExpandedPerson: setExpandedPerson,
        frontmatter: frontmatter,
        styles: styles,
      }, null)
      ReactDOM.render(modal, document.getElementById("boggs-school-modal"))
      document.getElementById("boggs-school-modal").focus()
    }
    
  }

  const gatsbySafeDocCheck =
    typeof window === `undefined` ? 0 : document.body.getBoundingClientRect()
  const [width, setWidth] = useState(gatsbySafeDocCheck)
  const [numCols, setNumCols] = useState(3)

  useEffect(() => {
    const resizeListener = () => {
      const gatsbySafeDocCheck =
        typeof window === `undefined`
          ? 0
          : document.body.getBoundingClientRect()
      setWidth(gatsbySafeDocCheck)

      if (width < DESKTOP_EX) {
        setNumCols(1)
      } else {
        setNumCols(3)
      }
    }
    window.addEventListener("resize", resizeListener)

    return window.removeEventListener("resize", resizeListener)
  }, [width])

  return (
    <div className={containerAnimation}>
      <section>
        <div className={styles.people_page_container}>
          <h1>{props.title}</h1>
          <PersonList
            data={data}
            pageStyle={pageStyle}
            numCols={numCols}
            setExpandedPerson={setExpandedPerson}
          />
        </div>
      </section>
    </div>
  )
}

ReusableTeamPage.propTypes = {
  pageStyle: PropTypes.string.isRequired,
  query: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
}

export default ReusableTeamPage
