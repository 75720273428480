import React from "react"
import TeamContentSwapper from "./team-content-swapper"
import * as styles from "./team.module.scss"

function Team({ currentSwapperItem, swapperVisible }) {
  return (
    <div className={styles.container}>
      <span />
      <TeamContentSwapper
        current={currentSwapperItem}
        visible={swapperVisible}
      />
    </div>
  )
}

export default Team
