import React from "react"
import * as styles from "../team.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import ReusableTeamPage from "../reusable-team-page"

function SupportStaff(props) {
  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsPeopleSupportStaff {
        edges {
          node {
            data {
              people {
                bio {
                  text
                }
                fun_image {
                  alt
                  gatsbyImageData
                }
                long_role {
                  text
                }
                name {
                  text
                }
                regular_image {
                  alt
                  gatsbyImageData
                }
                role {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <ReusableTeamPage
      pageStyle={styles.support_staff}
      data={query.allPrismicBoggsPeopleSupportStaff.edges[0].node.data.people}
      title={"Support Staff"}
      visible={props.visible}
    />
  )
}

export default SupportStaff
