import React from "react"
import * as styles from "../team.module.scss"
import { getContentSwapAnimation } from "../../content-swapper-utils"
import { useStaticQuery, graphql } from "gatsby"
import SummerInterns from "./summer-interns"
import ReusableTeamPage from "../reusable-team-page"

function Interns(props) {
  let containerAnimation = getContentSwapAnimation(props.visible)

  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsPeopleSocialWorkInterns {
        edges {
          node {
            data {
              people {
                bio {
                  text
                }
                fun_image {
                  alt
                  gatsbyImageData
                }
                long_role {
                  text
                }
                name {
                  text
                }
                regular_image {
                  alt
                  gatsbyImageData
                }
                role {
                  text
                }
              }
            }
          }
        }
      }
      allPrismicBoggsPeopleSummerInterns {
        edges {
          node {
            data {
              interns {
                blurb {
                  text
                }
                image {
                  alt
                  gatsbyImageData
                }
                year {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className={containerAnimation}>
      <ReusableTeamPage
        pageStyle={styles.social_work_interns}
        data={
          query.allPrismicBoggsPeopleSocialWorkInterns.edges[0].node.data.people
        }
        title={"Social Work Interns"}
        visible={props.visible}
      />
      <section>
        <div
          className={`${styles.people_page_container} ${styles.summer_interns_grid}`}
        >
          <h1>Summer Interns</h1>
          {query.allPrismicBoggsPeopleSummerInterns.edges[0].node.data.interns.map((nodeObj, index) => {
            return (
              <SummerInterns
                key={index}
                year={nodeObj.year.text}
                image={nodeObj.image}
                blurb={nodeObj.blurb.text}
              />
            )
          })}
        </div>
      </section>
    </div>
  )
}

export default Interns
