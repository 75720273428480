import React from "react"
import PropTypes from "prop-types"
import Leadership from "./leadership"
import { navItems } from "./team-nav-bar"
import Teachers from "./teachers"
import SupportStaff from "./support-staff"
import CommunityTeachers from "./community-teachers"
import BoardMembers from "./board-members"
import Interns from "./interns"
import JoinUsTeam from "./join-us"

function TeamContentSwapper(props) {
  switch (props.current) {
    case navItems.leadership.name:
      return <Leadership visible={props.visible} />
    case navItems.teachers.name:
      return <Teachers visible={props.visible} />
    case navItems.supportStaff.name:
      return <SupportStaff visible={props.visible} />
    case navItems.communityTeachers.name:
      return <CommunityTeachers visible={props.visible} />
    case navItems.interns.name:
      return <Interns visible={props.visible} />
    case navItems.boardMembers.name:
      return <BoardMembers visible={props.visible} />
    case navItems.joinUs.name:
      return <JoinUsTeam visible={props.visible} />
    default:
      return <></>
  }
}

export default TeamContentSwapper

TeamContentSwapper.propTypes = {
  current: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
}
