import React from "react"
import { getContentSwapAnimation } from "../../content-swapper-utils"
import * as styles from "../team.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

function JoinUsTeam(props) {
  let containerAnimation = getContentSwapAnimation(props.visible)

  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsImageBucket {
        edges {
          node {
            data {
              joins_us_1 {
                alt
                gatsbyImageData
              }
              joins_us_2 {
                alt
                gatsbyImageData
              }
              joins_us_3 {
                alt
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)

  const data = query.allPrismicBoggsImageBucket.edges[0].node.data

  return (
    <div
      className={`${containerAnimation} ${styles.join_us_team_container} ${styles.reverse_container_padding}`}
    >
      <section>
        <div>
          <h1>Building and Sustaining Talent</h1>
          <p>Apply for a position at the Boggs School!</p>
          <p>
            We accept resumes year-round and would love to read yours! Please
            watch our <a href="#blog">blog</a> and our{" "}
            <a
              href="http://www.facebook.com/pages/The-Boggs-Educational-Center/244370322273"
              target="_blank"
            >
              Facebook
            </a>{" "}
            page for current postings.
          </p>
        </div>
      </section>
      <section
        className={`${styles.section_bottom_triangle} ${styles.join_us_team_sectional}`}
      >
        <div className={styles.teacher_padding}>
          <div className={styles.join_us_image_container}>
            <GatsbyImage
              className={styles.join_us_image}
              alt={data.joins_us_1.alt}
              image={data.joins_us_1.gatsbyImageData}
            />
          </div>
          <h2>Teachers of Core Academic Subjects</h2>
          <p>
            Please send a resume and a letter of interest to Principal Julia
            Putnam at{" "}
            <a href="mailto:julia@boggsschool.org">
              <p className="inline" tabIndex={0}>
                julia@boggsschool.org
              </p>
            </a>
          </p>
        </div>
      </section>
      <section>
        <div>
          <div className={styles.join_us_image_container}>
            <GatsbyImage
              className={`${styles.join_us_image} ${styles.join_us_edge_case}`}
              alt={data.joins_us_2.alt}
              image={data.joins_us_2.gatsbyImageData}
            />
          </div>
          <h2>Classroom Aides &amp; Substitute Teachers</h2>
          <p>
            Please send a letter of interest to Principal Julia Putnam at{" "}
            <a href="mailto:julia@boggsschool.org">
              <p className="inline" tabIndex={0}>
                julia@boggsschool.org
              </p>
            </a>{" "}
          </p>
          <p>
            Applicants will need at least 60 credit hours from an accredited
            college or university.
          </p>
        </div>
      </section>
      <section
        className={`${styles.section_boxes} ${styles.join_us_team_sectional}`}
      >
        <div>
          <div className={styles.join_us_image_container}>
            <GatsbyImage
              className={styles.join_us_image}
              alt={data.joins_us_3.alt}
              image={data.joins_us_3.gatsbyImageData}
            />
          </div>
          <h2>
            Teachers of Arts, World Languages, Music, &amp; Other Speciality
            Areas
          </h2>
          <p>
            Please send a resume and a letter of interest to Programming
            Coordinator Anjela Bloom at{" "}
            <a href="mailto:anjela@boggsschool.org">
              <p className="inline" tabIndex={0}>
              anjela@boggsschool.org
              </p>
            </a>
          </p>
        </div>
      </section>
    </div>
  )
}

export default JoinUsTeam
