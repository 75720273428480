import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./team.module.scss"
import { useState } from "react"

function Person({
  name,
  bio,
  role,
  longRole,
  regImage,
  regImageAlt,
  funImage,
  funImageAlt,
  specialGridCss,
  setExpandedPerson,
}) {
  const [funImgVisible, setFunImgVisible] = useState(false)

  const hoverFunImage = () => {
    if (funImage && funImage !== undefined && funImage !== "undefined") {
      if (!funImgVisible) {
        setFunImgVisible(true)
      }
    }
  }

  const exitFunImage = () => {
    if (funImgVisible) {
      setFunImgVisible(false)
    }
  }

  return (
    <button
      onClick={() =>
        setExpandedPerson({
          name,
          bio,
          role,
          longRole,
          regImage,
          regImageAlt,
          funImage,
          funImageAlt,
        })
      }
      onKeyDown={(e) => {
        if (e.key === "enter") {
          setExpandedPerson({
            name,
            bio,
            role,
            longRole,
            regImage,
            regImageAlt,
            funImage,
            funImageAlt,
          })
        }
      }}
      className={`${styles.person} ${specialGridCss}`}
    >
      <image
        tabIndex={0}
        onMouseOver={() => hoverFunImage()}
        onMouseLeave={() => {
          exitFunImage()
        }}
        className={styles.circle}
      >
        {!funImgVisible && (
          <div>
            <GatsbyImage alt={regImageAlt} image={regImage} />
          </div>
        )}
        {funImgVisible && funImage && (
          <div>
            <GatsbyImage alt={funImageAlt} image={funImage} />
          </div>
        )}
      </image>
      <h2 tabIndex={0}>
        {name}
      </h2>
      <hr />
      <p>{role}</p>
    </button>
  )
}

export default Person
